import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import Layout from '~/components/Layout';
import MemberItem from '~/components/MemberItem';
import '../equipo/equipo.scss';

export const TeamPageTemplate = ({ members = null }) => {
  return (
    <article>
      <div className="member-list">
        {members &&
          members.map(member => (
            <MemberItem key={member.slug} member={member} />
          ))}
      </div>
    </article>
  );
};

TeamPageTemplate.propTypes = {
  members: PropTypes.array.isRequired,
};

const TeamPage = ({ data }) => {
  const members = data.members.edges
    .map(edge => ({
      slug: edge.node.fields.slug,
      ...edge.node.frontmatter,
    }))
    .filter(member => member.teamCategory?.includes('category-DBdfgC3Ge'));

  return (
    <Layout footerData={data.footerData} navbarData={data.navbarData}>
      <Helmet>
        <meta name="title" content="Fotógrafos :: Mitá" />
        <meta name="description" content="Fotógrafos :: Mitá" />
        <title>Fotógrafos :: Mitá</title>
      </Helmet>
      <TeamPageTemplate members={members} />
    </Layout>
  );
};

TeamPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TeamPage;

export const pastMeetupsPageQuery = graphql`
  query PhotographersPage {
    ...LayoutFragment
    members: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "equipo-miembro" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            description
            image
            teamCategory
          }
        }
      }
    }
  }
`;
